import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_STORAGEGRADES } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Storage Grades",
      "search-by": "WarehouseCode,Name,Description",
      "ph-search-by": "Search by Warehouse, Storage Grade, Storage Grade Description"
    }
  }, [_c("text-field", {
    key: "WarehouseCode",
    attrs: {
      "data-index": "warehouseCode",
      "title": "Warehouse"
    }
  }), _c("text-field", {
    key: "Name",
    attrs: {
      "data-index": "name",
      "title": "Storage Grade"
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Storage Grade Description"
    }
  }), _c("text-field", {
    key: "MaxStorageDays",
    attrs: {
      "data-index": "maxStorageDays",
      "title": "Max Storage Days"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListStorageGrades"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListStorageGrades = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-storage-grades"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.ware-houses",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.storage-grades",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/storage-grades/create",
      "edit-route": "/watties-grower/storage-grades/:id",
      "list": _vm.ListStorageGrades,
      "page": _vm.page
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "StorageGrades",
  data() {
    return {
      ListStorageGrades,
      apiUrl,
      page: GROWERS_STORAGEGRADES,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "storage-grades",
          title: "Storage Grades",
          path: "/watties-grower/storage-grades"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
